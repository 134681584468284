<template>
  <div class="h-screen w-screen lg:flex lg:justify-center lg:items-center bg-background">
    <div v-if="!isMobile" class="fixed w-full h-full">
      <ImageFader v-if="images" :images="images" />
    </div>

    <div class="z-10 w-full lg:w-100 h-full lg:flex lg:items-center text-text">
      <div class="w-full">
        <div class="bg-interface">
          <div class="flex items-center pt-10 pb-2">
            <img :src="logoSvg" class="w-80 m-auto" />
          </div>
          <div
            class="font-bold tracking-wide lg:tracking-wider p-6 pb-2 text-center pt-2 text-sm uppercase text-akzent"
          >
            Fantasy-Action Movie Production
          </div>
          <div class="flex items-center justify-center pb-2">
            <LanguageSwitch />
          </div>
        </div>
        <div v-if="isMobile" class="w-full">
          <ImageFader v-if="images" :images="images" :padding-bottom="'56.25%'" />
        </div>

        <div class="pt-8 pb-4 px-8 lg:px-12 lg:bg-interface mt-px">
          <slot />
        </div>
        <div class="pt-2 pb-6 px-8 lg:px-12 text-xs lg:bg-interface">
          <div class="flex flex-col items-center text-xs py-4">
            <div class="inline-flex items-center">
              <span class="hover:underline cursor-pointer" @click="openOverlayWith('imprint')">
                {{ $t('imprint') }}
              </span>
              <span class="h-4 mx-1 w-px border-r border-text" />
              <span
                class="hover:underline cursor-pointer"
                @click="openOverlayWith('privacy_policy')"
              >
                {{ $t('privacyPolicy') }}
              </span>
              <span class="h-4 mx-1 w-px border-r border-text" />
              <span class="hover:underline cursor-pointer" @click="openOverlayWith('agb')">
                {{ $t('agb') }}
              </span>
              <span class="h-4 mx-1 w-px border-r border-text" />
              <span
                class="hover:underline cursor-pointer"
                @click="openOverlayWith('cancellation_policy')"
              >
                {{ $t('cancellationPolicy') }}
              </span>
            </div>

            <div class="mt-1">Copyright 2021 Pontus Media</div>
          </div>
        </div>
      </div>
    </div>
    <Overlay v-model="showOverlay" close-icon>
      <div class="max-w-4xl cms-content h-screen overflow-y-auto">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="overlayContentTranslation" />
      </div>
    </Overlay>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import logoSvg from '@/assets/logo.svg';
import { ImageFader, LanguageSwitch } from '../../components';

export default Vue.extend({
  components: {
    ImageFader,
    LanguageSwitch,
  },

  data: () => ({
    activeImage: 0,
    images: null,
    showOverlay: false,
    overlayContent: null,
    overlayLoaded: false,
    logoSvg,
  }),

  computed: {
    ...mapGetters(['isMobile']),
    overlayContentTranslation() {
      if (this.overlayContent) {
        let translation = this.overlayContent.translations.find((translation) =>
          translation.languages_code.startsWith(this.$i18n.locale),
        ).content;
        if (!translation) {
          translation = this.overlayContent.translations[0].content;
        }
        return translation;
      }

      return this.overlayContent;
    },
  },

  async created() {
    const resp = await this.$axios(
      `${process.env.VUE_APP_DIRECTUS_URL}/items/background_gallery?fields=*,images.*`,
    );
    this.images = resp.data.data.images.map(
      (image) => `${process.env.VUE_APP_DIRECTUS_URL}/assets/${image.directus_files_id}`,
    );
  },

  methods: {
    async openOverlayWith(cmsPage) {
      this.overlayContent = null;
      this.overlayLoaded = false;
      this.showOverlay = true;

      if (cmsPage === 'agb') {
        const resp = await this.$axios(
          // eslint-disable-next-line max-len
          `${process.env.VUE_APP_DIRECTUS_URL}/items/${cmsPage}?fields=*,translations.*&filter[active]=true`,
        );
        [this.overlayContent] = resp.data.data;
      } else {
        const resp = await this.$axios(
          // eslint-disable-next-line max-len
          `${process.env.VUE_APP_DIRECTUS_URL}/items/${cmsPage}?fields=*,translations.*`,
        );
        this.overlayContent = resp.data.data;
      }

      this.overlayLoaded = true;
    },
  },
});
</script>
